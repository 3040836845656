@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

@media (max-width: 768px) {
  .qabig {
    display: none;
  }
}

@media (max-width: 1024px) {
  .qaqbig {
    display: none;
  }
}

@media (min-width: 680px) and (max-width: 1300px) {
  .uxdisplay {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 5rem;
  }
}

@media (min-width: 768px) {
  .background-main {
    background-image: url(./images/1st-screen-img.jpg);
  }
}

@media (min-width: 768px) {
  .background-uiux {
    background-image: url(./images/ui-ux.jpg);
  }
}

@media (min-width: 768px) {
  .background-qa {
    background-image: url(./images/QA1st.jpg);
  }
}

@media (min-width: 768px) {
  .background-webmobdev {
    background-image: url(./images/web-mobile-development.jpg);
  }
}

.pink-line {
  background-image: url(./images/bg.svg);
}

html {
  scroll-behavior: smooth;
}

.figure {
  display: grid;
  grid-template-column: 1fr;
  grid-template-rows: repeat(20, auto);
}

header {
  grid-column: 1/2;
  grid-row: 1 / span 1;
  z-index: 10;
}

.myImg {
  grid-column: 1/2;
  grid-row: 1/20;

  object-fit: cover;
}

.myImgUx {
  grid-column: 1/2;
  grid-row: 1/20;

  object-fit: cover;
  object-position: 80% 50%;
}

.myImgTerms {
  grid-column: 1/2;
  grid-row: 1/20;

  object-fit: cover;
  object-position: 60% 50%;
}

.myImgPrivacy {
  grid-column: 1/2;
  grid-row: 1/20;

  object-fit: cover;
  object-position: 53% 50%;
}

.figcaption,
figcaption {
  grid-column: 1/2;
  grid-row: 9 / span 1;

  padding: 20px 5px 10px 0px;
}

.slider-indicator {
  grid-column: 1/2;
  grid-row: 18 / span 1;
  z-index: 10;
}

@font-face {
  font-family: "Gilroy";
  src: url("./font/Gilroy Font/Gilroy-Regular.ttf");
}

@font-face {
  font-family: "GilroyMedium";
  src: url("./font/Gilroy Font/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "Gilroy-Semibold";
  src: url("./font/Gilroy Font/Gilroy-Semibold.ttf");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("./font/Gilroy Font/Gilroy-Bold.ttf");
}

@font-face {
  font-family: "Gilroy-Thin";
  src: url("./font/Gilroy Font/Gilroy-Thin.ttf");
}

@font-face {
  font-family: "Gilroy-Light";
  src: url("./font/Gilroy Font/Gilroy-Light.ttf");
}

@font-face {
  font-family: "Rokkitt";
  src: url("./font/Rokkitt Font/Rokkitt-Regular.ttf");
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.hamburger.active .bar:nth-child(2) {
  opacity: 0;
}
.hamburger.active .bar:nth-child(1) {
  transform: translateY(8.5px) rotate(45deg);
  width: 32px;
  margin-left: auto;
  margin-right: auto;
}
.hamburger.active .bar:nth-child(3) {
  transform: translateY(-8.5px) rotate(-45deg);
  width: 32px;
  margin-left: auto;
  margin-right: auto;
}

.hidden {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.services-dropdown-menu.active {
  transform: scale(1);
  transform: translateY(0);
  display: flex;
}

@media (min-width: 1024px) {
  .line {
    color: white;
    position: relative;
    text-decoration: none;
    padding: 2px 10px;
  }

  .line::before {
    background: #c026d3;
    content: "";
    inset: 0;
    position: absolute;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease-in-out;
    z-index: -1;
  }

  .line:hover::before {
    transform: scaleX(1);
    transform-origin: left;
    padding: 2px 10px;
  }

  .lineservices {
    color: white;
    position: relative;
    text-decoration: none;
    padding: 2px 10px;
  }

  .lineservices::before {
    background: #c026d3;
    content: "";
    inset: 0;
    position: absolute;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.5s ease-in-out;
    z-index: -1;
  }

  .lineservices:hover::before {
    transform: scaleX(1);
    transform-origin: left;
  }
}

.bottomline {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.navbar {
  transition: background-color 250ms ease;
}

.navbar.scrolled {
  background-color: rgba(0, 0, 0, 0.2);
}

.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

.mySlides {
  display: none;
}

.prev,
.next {
  cursor: pointer;

  user-select: none;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.translate-text-from-right {
  transform: translateX(10vw);
}

.translate-text-from-left {
  transform: translateX(-10vw);
}

.show-translate {
  transform: translateX(0);
  opacity: 1;
}

.show-transparent {
  opacity: 1;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;

  border-left: 30px solid green;
}

.translate-message-from-left {
  transform: translateX(5vw);
}

.translate-message-from-right {
  transform: translateX(-5vw);
}

.show-translate-message {
  transform: translateX(0vw);
  opacity: 1;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;

  border-left: 30px solid #f5d0fe;
}

.arrow-right-unchanged {
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;

  border-left: 30px solid #f5d0fe;
}

.arrow-right-changed {
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;

  border-left: 30px solid #c026d3;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;

  border-right: 30px solid #f5d0fe;
}

.arrow-left-unchanged {
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;

  border-right: 30px solid #f5d0fe;
}

.arrow-left-changed {
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;

  border-right: 30px solid #c026d3;
}

@keyframes text-from-right {
  0% {
    opacity: 0;
    transform: translateX(100px);
    filter: blur(10px);
  }
  25% {
    opacity: 1;
    transform: translateX(0px);
    filter: blur(0px);
  }
  75% {
    opacity: 1;
    transform: translateX(0px);
    filter: blur(0px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
    filter: blur(0px);
  }
}

.text-from-right {
  opacity: 0;
  animation-name: text-from-right;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

@keyframes text-from-left {
  0% {
    opacity: 0;
    transform: translateX(-100px);
    filter: blur(10px);
  }
  25% {
    opacity: 1;
    transform: translateX(0px);
    filter: blur(0px);
  }
  75% {
    opacity: 1;
    transform: translateX(0px);
    filter: blur(0px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
    filter: blur(0px);
  }
}

.text-from-left {
  opacity: 0;
  animation-name: text-from-left;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

@keyframes header {
  0% {
    opacity: 0;
    transform: translateY(-100px) skewX(0deg) skewY(0deg) rotateZ(0deg);
    filter: blur(10px);
  }

  25% {
    opacity: 1;
    transform: translateY(0px) skewX(0deg) skewY(0deg) rotateZ(0deg);
    filter: blur(0px);
  }

  75% {
    opacity: 1;
    transform: translateY(0px) skewX(0deg) skewY(0deg) rotateZ(0deg);
    filter: blur(0px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px) skewX(0deg) skewY(0deg) rotateZ(0deg);
    filter: blur(0px);
  }
}

.text-header {
  opacity: 0;
  animation-name: header;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

@keyframes above {
  0% {
    opacity: 0;
    transform: translateY(-50px) skewX(0deg) skewY(0deg) rotateZ(0deg);
    filter: blur(5px);
  }

  25% {
    opacity: 1;
    transform: translateY(0px) skewX(0deg) skewY(0deg) rotateZ(0deg);
    filter: blur(0px);
  }

  75% {
    opacity: 1;
    transform: translateY(0px) skewX(0deg) skewY(0deg) rotateZ(0deg);
    filter: blur(0px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px) skewX(0deg) skewY(0deg) rotateZ(0deg);
    filter: blur(0px);
  }
}

.text-from-above {
  opacity: 0;
  animation-name: above;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

.glass-effect1 {
  background-image: url(./images/glass-effest.png);
}

.glass-effect2 {
  background-image: url(./images/glass-effest-\(1\).png);
}

.glass-effect3 {
  background-image: url(./images/glass-effest-\(2\).png);
}

.glass-effect4 {
  background-image: url(./images/glass-effest-\(3\).png);
}

@keyframes sphere-changed {
  0% {
    background-color: #f5d0fe;
    color: #585858;
  }
  25% {
    background-color: #c026d3;
    color: #f5f5f5;
  }
  75% {
    background-color: #c026d3;
    color: #f5f5f5;
  }
  100% {
    background-color: #c026d3;
    color: #f5f5f5;
  }
}

.sphere-changed {
  background-color: #f5d0fe;
  color: #585858;

  animation-name: sphere-changed;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.sphere-unchanged {
  background-color: #f5d0fe;
  color: #585858;
}

@media (min-width: 1024px) and (max-width: 1475px) {
  .mediaq {
    flex-direction: column;
    display: flex;
    justify-content: center;
  }
}

@keyframes arrow-animation-1 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.arrow-animation-1 {
  opacity: 0;
  animation-name: arrow-animation-1;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-delay: 500ms;
}

@keyframes arrow-animation-2 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.arrow-animation-2 {
  opacity: 0;
  animation-name: arrow-animation-2;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-delay: 250ms;
}

@keyframes arrow-animation-3 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.arrow-animation-3 {
  opacity: 0;
  animation-name: arrow-animation-3;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@media (max-width: 770px) {
  .arr {
    display: none;
  }
}

@keyframes web-text-from-right {
  0% {
    opacity: 0;
    transform: translateX(100px);
    filter: blur(10px);
  }
  25% {
    opacity: 1;
    transform: translateX(-10px);
    filter: blur(0px);
  }
  75% {
    opacity: 1;
    transform: translateX(10px);
    filter: blur(0px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
    filter: blur(0px);
  }
}

.web-text-from-left {
  opacity: 0;
  animation-name: web-text-from-left;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

@keyframes web-text-color-change {
  0% {
    background-color: #f5d0fe;
    color: #585858;
  }
  25% {
    background-color: #c026d3;
    color: #f5f5f5;
  }
  75% {
    background-color: #c026d3;
    color: #f5f5f5;
  }
  100% {
    background-color: #c026d3;
    color: #f5f5f5;
  }
}

.web-text-color-change {
  background-color: #f5d0fe;
  color: #585858;
  animation-name: web-text-color-change;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.text {
  color: #585858;
}

.text-unchanged {
  color: #585858;
}

.text-changed {
  color: #f5f5f5;
}

.message {
  background-color: #f5d0fe;
  color: #585858;
}

.message-unchanged {
  background-color: #f5d0fe;
  color: #585858;
}

.message-changed {
  background-color: #c026d3;
  color: #f5f5f5;
}

.path {
  fill: none;
  stroke: #ffffff;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  --length: 24;
  --offset: -38;
  stroke-dasharray: var(--length) var(--total-length);
  stroke-dashoffset: var(--offset);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.line--1.activate,
.line--3.activate {
  --length: 22.627416998;
}

.line--2.activate {
  --length: 0;
}

.line--1.activate,
.line--3.activate {
  --length: 22.627416998;
}

.line--2.activate {
  --length: 0;
}

.line--1,
.line--3 {
  --total-length: 126.64183044433594;
}

.line--2 {
  --total-length: 70;
}

.line--1.activate,
.line--3.activate {
  --offset: -94.1149185097;
}

.line--2.activate {
  --offset: -50;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(88 88 88);
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(245 208 254);
  border-radius: 5px;
}

.button-submit {
  border: none;
  outline: none;

  color: #f5f5f5;

  border-radius: 8px;
  text-align: center;

  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.check-box {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  box-shadow: 0 0 12px -2px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 6px;
  right: -20px;
  opacity: 0;
}

.check-box svg {
  width: 40px;
  margin: 20px;
}

.submit-svg path {
  stroke-width: 3;
  stroke: #fff;
  stroke-dasharray: 34;
  stroke-dashoffset: 34;
  stroke-linecap: round;
}

.active-submit {
  background: #404040;

  transition: 1s;
}

.active-submit .check-box {
  top: 6px;
  right: 35%;
  opacity: 1;
  transition: 2s;
}

.active-submit p {
  margin-right: 125px;
  transition: 2s;
}

.active-submit svg path {
  stroke-dashoffset: 0;
  transition: 1s;
  transition-delay: 1s;
}
